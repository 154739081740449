import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showMenu: false, // 手機版選單
    showSearchModal: false, // 搜尋彈窗
    showUserMenuModal: false, // 使用者選單彈窗
    showNotifyModal: false, // 通知彈窗
    showIdleModal: false, // 閒置彈窗
    showAcademyModal: false, // 加密學院彈窗
    showFullImageModal: false, // 新聞全版圖片彈窗
    showCopyHint: false, // 已複製提示
    podcastPlayer: false, // podcast 播放內容
    showLoginModal: false, // 登入彈窗
    greedyModal: false, // 恐懼指數彈窗
    showSpeakerModal: false, // 活動講者彈窗
    showNewsProgress: false, // 新聞文章進度條
    newsBar: { title: '', progress: 0, newsId: null }, // 新聞文章進度條
    showCatalog: false,
    hintText: '', // 複製文字
    showCampaignHint: false, // 常駐活動提示
    campaignHint: null, // 常駐活動提示文字
    showCampaignBusy: false, // 常駐活動繁忙
    showCampaignSpin: false, // 常駐活動輪盤
    showCampaignRecord: false, // 常駐活動紀錄
    showCampaignWinner: false, // 常駐活動中獎名單
    campaignEnd: {}, // 常駐活動狀態
    campaignUserInfo: {}, // 常駐活動玩家資訊
    showExchangeModal: false, // 兌換任務彈窗
    indexMarginTop: 0, // 首頁 marginTop
};

export const popSlice = createSlice({
    name: 'pop',
    initialState,
    reducers: {
        setMenu: (state, action) => {
            state.showMenu = action.payload;
        },
        setSearchModal: (state, action) => {
            state.showSearchModal = action.payload;
        },
        setUserMenuModal: (state, action) => {
            state.showUserMenuModal = action.payload;
        },
        setNotifyModal: (state, action) => {
            state.showNotifyModal = action.payload;
        },
        setIdleModal: (state, action) => {
            state.showIdleModal = action.payload;
        },
        setAcademyModal: (state, action) => {
            state.showAcademyModal = action.payload;
        },
        setFullImageModal: (state, action) => {
            state.showFullImageModal = action.payload;
        },
        setCopyHint: (state, action) => {
            state.showCopyHint = action.payload;
        },
        setPodcastPlayer: (state, action) => {
            state.podcastPlayer = action.payload;
        },
        setGreedyModal: (state, action) => {
            state.greedyModal = action.payload;
        },
        setLoginModal: (state, action) => {
            state.showLoginModal = action.payload;
        },
        setSpeakerModal: (state, action) => {
            state.showSpeakerModal = action.payload;
        },
        setNewsProgress: (state, action) => {
            state.showNewsProgress = action.payload;
        },
        setNewsBar: (state, action) => {
            state.newsBar = action.payload;
        },
        setCatalog: (state, action) => {
            state.showCatalog = action.payload;
        },
        setHintText: (state, action) => {
            state.hintText = action.payload;
        },
        setCampaignHintModal: (state, action) => {
            state.showCampaignHint = action.payload;
        },
        setCampaignHint: (state, action) => {
            state.campaignHint = action.payload;
        },
        setCampaignSpinModal: (state, action) => {
            state.showCampaignSpin = action.payload;
        },
        setCampaignRecordModal: (state, action) => {
            state.showCampaignRecord = action.payload;
        },
        setCampaignUserInfo: (state, action) => {
            state.campaignUserInfo = action.payload;
        },
        setCampaignWinnerModal: (state, action) => {
            state.showCampaignWinner = action.payload;
        },
        setCampaignBusyModal: (state, action) => {
            state.showCampaignBusy = action.payload;
        },
        setExchangeModal: (state, action) => {
            state.showExchangeModal = action.payload;
        },
        setIndexMarginTop: (state, action) => {
            state.indexMarginTop = action.payload;
        },
    },
});

export const popState = (state) => state.pop;

export const {
    setMenu,
    setSearchModal,
    setUserMenuModal,
    setNotifyModal,
    setIdleModal,
    setAcademyModal,
    setFullImageModal,
    setCopyHint,
    setPodcastPlayer,
    setGreedyModal,
    setLoginModal,
    setSpeakerModal,
    setNewsProgress,
    setNewsBar,
    setCatalog,
    setHintText,
    setCampaignHintModal,
    setCampaignHint,
    setCampaignSpinModal,
    setCampaignRecordModal,
    setCampaignUserInfo,
    setCampaignWinnerModal,
    setCampaignBusyModal,
    setCampaignEnd,
    setExchangeModal,
    setIndexMarginTop,
} = popSlice.actions;

export default popSlice.reducer;
